import React from 'react';

import UpArrow from 'components/shared/icons/UpArrow';

import style from './index.module.scss';

const TopButton = ({
  targetRef = null
}) => {

  function onClick(e) {
    targetRef.current.scrollIntoView({
      behavior: 'smooth'
    });
  }

  return (
    <div className={style.wrap}>
      <button onClick={onClick}>
        <UpArrow color={style.navColor} />
      </button>
    </div>
  );
}

export default TopButton;
