import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from 'components/App';

import 'styles/reset.scss';

createRoot(document.getElementById('root'))
  .render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  );