import React from 'react';

import AnchorRef from 'components/shared/AnchorRef';
import TopButton from 'components/shared/TopButton';

// import style from './index.module.scss';

const PressSection = ({
  topRef = null,
  pressRef = null
}) => {

  return (
    <section>

      <AnchorRef targetRef={pressRef} />
      <TopButton targetRef={topRef} />

      <h3>In the press...</h3>

      <ol>
        <li>INSITU was featured in <a href='https://adage.com/article/marketing-news-strategy/amazon-ads-box-advertising-4x-more-effective-traditional-media/2440636' target='_blank' rel='noopener noreferrer'>AdAge</a> and <a href='https://advertising.amazon.com/en-us/blog/paramount-box-advertising' target='_blank' rel='noopener noreferrer'>Amazon's Advertising Blog</a> (2022).</li>

        <li>Contributed to and quoted in the e-book, <em>Design Landscape: Complete Guide To Digital Design Jobs In 202x</em> by Serafima Aleksandrova (2022). You can download the e-book <a href='https://www.producthunt.com/posts/design-landscape-in-202x' target='_blank' rel='noopener noreferrer'>here</a>.</li>

        <li>Interviewed by <a href='https://www.flowmapp.com/blog' target='_blank' rel='noopener noreferrer'>FlowMapp</a> for their design blog on the evolution of the Design Technologist role (2021). You can find the full interview <a href='https://www.flowmapp.com/blog/design-interviews/joseph-brust' target='_blank' rel='noopener noreferrer'>here</a>.</li>

        {/* <li>Inventor on submitted <em>US Patent File No. P74167-US01: Print Rollover Offset Control</em>. This patent is still ungoing review by USPTO.</li> */}

        <li>Primary inventor on <em>US Patent No. 10861060: Dynamically Determining Personalized Promotional Offers Using Markers</em> (2020). Official patent can be found <a href='https://image-ppubs.uspto.gov/dirsearch-public/print/downloadPdf/10861060' target='_blank' rel='noopener noreferrer'>here</a>.</li>

        <li>Contributed to the IAB's <a href='https://www.iab.com/wp-content/uploads/2019/10/IAB-AR-for-Marketing-Playbook-FINAL-June-2019.pdf' target='_blank' rel='noopener noreferrer'>Augmented Reality For Marketing</a> playbook on behalf of Amazon (2019).</li>

        <li>Contributed to the IAB's <a href='https://www.iabtechlab.com/wp-content/uploads/2016/02/IAB_MRAID_v2_FINAL.pdf' target='_blank' rel='noopener noreferrer'>Mobile Rich-media Ad Interface Definitions (MRAID) v.2.0</a> on behalf of PointRoll (2012).</li>
      </ol>

    </section>
  );

}

export default PressSection;
