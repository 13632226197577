import * as React from 'react';

const UpArrow = ({ color = '#000' }) => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 45 13'
    width='45px'
    height='13px'>
    <polygon
      fill={color}
      transform='translate(22.353553, 6.207107) scale(-1, 1) translate(-22.353553, -6.207107) '
      points='4.70710678 0 9.41421356 4.70710678 8 6.12132034 5.707 3.829 5.70710678 10.4142136 44.7071068 10.4142136 44.7071068 12.4142136 3.70710678 12.4142136 3.707 3.829 1.41421356 6.12132034 0 4.70710678' />
  </svg>
);

export default UpArrow;