import React from 'react';

import AnchorRef from 'components/shared/AnchorRef';
import TopButton from 'components/shared/TopButton';

import projects from 'data/projects.json';

import style from './index.module.scss';

const DevSection = ({
  topRef = null,
  devRef = null
}) => {

  const devProjects = projects?.filter((p) => p.type === 'dev') || [ ];

  function renderDevSections() {
    return devProjects?.map((p, i) => {

      const isFirst = i === 0;

      return (
        <section key={p.title}>

          <AnchorRef targetRef={isFirst ? devRef : null} />

          {!isFirst && (
            <TopButton targetRef={topRef} />
          )}

          <h3>{p.title}</h3>

          {p.schema.map((schema, i) => {

            if (schema.type === 'text') {
              return (
                <p key={p.title + i}>{schema.text}</p>
              );
            }

            if (schema.type === 'image') {
              return (
                <div
                  className={style.wrapImg}
                  key={p.title + i}>
                  <img
                    src={schema.src}
                    width='100%'
                    height='auto'
                    alt={schema.src} />
                </div>
              );
            }

            return null;

          })}

        </section>
      );

    });
  }

  return (
    <>
      {renderDevSections()}
    </>
  );

}

export default DevSection;
