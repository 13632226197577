import React from 'react';

import style from './index.module.scss';

const AnchorRef = ({
  targetRef = null
}) => {

  return (
    <div 
      className={style.wrap}
      ref={targetRef} />
  );
  
}

export default AnchorRef;
