import React from 'react';

import AnchorRef from 'components/shared/AnchorRef';
import TopButton from 'components/shared/TopButton';

// import style from './index.module.scss';

const ContactSection = ({
  topRef = null,
  contactRef = null
}) => {

  return (
    <section>

      <AnchorRef targetRef={contactRef} />
      <TopButton targetRef={topRef} />

      <h3>Contact me</h3>

      <p>
        You can find me on <a href='https://www.linkedin.com/in/joebrust/' target='_blank' rel='noopener noreferrer'>LinkedIn</a>.<br />
        View some select code repos on <a href='https://bitbucket.org/brustj/' target='_blank' rel='noopener noreferrer'>Bitbucket</a>.<br />
        Feel free to <a href='mailto:josephbrust@gmail.com' target='_blank' rel='noopener noreferrer'>email me</a> with any other type of inquiry!
      </p>

    </section>
  );

}

export default ContactSection;
