import React, { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';

import ScrollToTop from 'components/shared/ScrollToTop';
import ErrorView from 'components/shared/ErrorView';

import projects from 'data/projects.json';

import style from './index.module.scss';

const DetailsView = () => {

  const { projectId } = useParams();

  const project = projects.find((p) => p.id === projectId);

  if (!project?.id) return <ErrorView />;

  function renderDetail(schema, index) {

    if (schema.type === 'text') {
      const hasHeader = !!schema.header?.length;
      return (
        <Fragment key={project.title + index}>
          {hasHeader && <h3>{schema.header}</h3>}
          <p dangerouslySetInnerHTML={{ __html: schema.text }} />
        </Fragment>
      );
    }

    if (schema.type === 'image') {
      return (
        <div
          className={style.wrapImg}
          key={project.title + index}>
          <img
            src={schema.src}
            width='100%'
            height='auto'
            alt={schema.src} />
        </div>
      );
    }

    return null;

  }

  return (
    <div className={style.wrap}>

      <ScrollToTop />

      <header>
        <div className={style.name}>
          <Link to='/'>Joe Brust</Link>
        </div>

        <nav>
          <Link to='/'>
            <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back
          </Link>
        </nav>
      </header>

      <div className={style.wrapMast}>
        <h1>{project?.title}</h1>
      </div>

      <main>
        <section>
          {project?.detailsSchema?.map((schema, i) =>
            renderDetail(schema, i)
          )}
        </section>
      </main>

    </div>
  );
}

export default DetailsView;
