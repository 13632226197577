import React, { useRef } from 'react';

import DevSection from './DevSection';
import WoodSection from './WoodSection';
import PressSection from './PressSection';
import ContactSection from './ContactSection';

import style from './index.module.scss';

const HomeView = () => {

  const topRef     = useRef(),
        devRef     = useRef(),
        woodRef    = useRef(),
        pressRef   = useRef(),
        contactRef = useRef();

  function jumpTo(ref) {
    ref?.current?.scrollIntoView({
      behavior: 'smooth'
    });
  }

  return (
    <div
      className={style.wrap}
      ref={topRef}>

      <header>
        <div className={style.name}>Joe Brust</div>

        <nav>
          <button onClick={(e) => jumpTo(devRef)}>Dev</button>
          <button onClick={(e) => jumpTo(woodRef)}>Wood</button>
          <button onClick={(e) => jumpTo(pressRef)}>Press</button>
          <button onClick={(e) => jumpTo(contactRef)}>Contact</button>
        </nav>
      </header>

      <div className={style.wrapMast}>
        <h1>
          Developer.<br />
          Design Technologist.<br />
          Woodworker?
        </h1>
      </div>

      <main>
        <section>

          <h2>👋 Hi there!</h2>

          <p>I'm Joe, a design technologist that specializes in web-based UI/UX and CX. I recently joined the HubSpot Design System team as Senior Product Designer II / Design Technologist and will be focusing on internal tooling as well as continuing my journey to help bridge the gap between design and engineering. I'll have more details and projects to share once I get settled into the new role!</p>

          <p>In my spare time, I love to exercise (biking or running) and have taken on a relatively newer hobby of woodworking. You can find a random collection of my projects down below.</p>

        </section>

        <DevSection
          topRef={topRef}
          devRef={devRef} />

        <WoodSection
          topRef={topRef}
          woodRef={woodRef} />

        <PressSection
          topRef={topRef}
          pressRef={pressRef} />

        <ContactSection
          topRef={topRef}
          contactRef={contactRef} />
      </main>

    </div>
  );
}

export default HomeView;
