import { useEffect, useState } from 'react';

export const useTheme = () => {

  const [ isDark, setIsDark ] = useState(false);

  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) =>
        setIsDark(e.matches)
      );

    setIsDark(
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .matches
    );

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', (e) =>
          setIsDark(e.matches)
        );
    }
  }, [ ]);

  return {
    isDark
  };

};
