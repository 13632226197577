import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import ErrorView from 'components/shared/ErrorView';

import HomeView from './HomeView';
import DetailsView from './DetailsView';

import { useTheme } from 'hooks/theme';

import style from './index.module.scss';

const App = () => {

  const { isDark } = useTheme();

  useEffect(() => {
    document.body.setAttribute('data-theme', isDark ? 'dark' : 'default');
  }, [
    isDark
  ]);

  return (
    <div className={style.wrap}>
      <div className={style.wrapContent}>
        <Routes>
          <Route path='/' element={<HomeView />} />
          <Route path='/details/:projectId' element={<DetailsView />} />
          <Route path='/*' element={<ErrorView />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
