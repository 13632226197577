import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';

import style from './index.module.scss';

const ErrorView = () => {

  return (
    <div className={style.wrap}>

      <header>
        <div className={style.name}>
          <Link to='/'>Joe Brust</Link>
        </div>

        <nav>
          <Link to='/'>
            <FontAwesomeIcon icon={faLongArrowAltLeft} /> Home
          </Link>
        </nav>
      </header>

      <div className={style.wrapMast}>
        <h1>
          Sorry, I can't seem to find that page.
        </h1>
      </div>

      <main>

      </main>

    </div>
  );
}

export default ErrorView;
