import React from 'react';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import AnchorRef from 'components/shared/AnchorRef';
import TopButton from 'components/shared/TopButton';

import projects from 'data/projects.json';

import style from './index.module.scss';

const WoodSection = ({
  topRef = null,
  woodRef = null
}) => {

  const woodProjects = projects?.filter((p) => p.type === 'wood') || [ ];

  const breakpointColumnsObj = {
    default: 3,
    800: 2,
    600: 1
  };

  function renderProjectGrid() {
    return woodProjects?.map((p, i) => (
      <Link
        key={`wood-${i}`}
        to={`/details/${p.id}`}>
        <div className={style.wrapThumb}>
          <img
            src={p.src}
            width='100%'
            height='auto'
            alt={p.title} />

          <div className={style.wrapVeil}>
            <FontAwesomeIcon icon={faSearch} />
          </div>
        </div>
      </Link>
    ));
  }
  
  const getStartYear = () => new Date().getFullYear() - 2018;

  return (
    <section>

      <AnchorRef targetRef={woodRef} />
      <TopButton targetRef={topRef} />

      <h3>Woodworking</h3>

      <p>I've been woodworking for a little over {getStartYear()} years now and am completely self taught (with the help of a few thousand hours of YouTube videos at this point). I've slowly been growing my tool collection but still consider myself a beginner. All my projects have either been for myself or friends and family.</p>

      <p>Web development is a great way to create and see instant results but, for me, nothing beats making something physical with your hands.</p>

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className={style.wrapMasonryGrid}
        columnClassName={style.masonryGridCol}>
        {renderProjectGrid()}
      </Masonry>

    </section>
  );

}

export default WoodSection;
